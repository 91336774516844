.page {
    display: flex;
    max-width: 100%;
    flex-direction: column;
    .row {
        justify-content: center;
        width: 100%;
    }

    .start {
        justify-content: start;;
    }
}

@media only screen and (max-width: 768px) {
    .page{
        .row{
            justify-content: start;
            width: 100%;
        }

    }
}
   