.featured-articles {
    display: flex;
    justify-content: center;
    background-color: #050916;
    width: 100%;
    height: auto;
    padding: 0;
    overflow-x: hidden;

    .carousel-custom {
        width: 100%;
        margin-top: 100px;
        height: auto;
    }

    .slick-dots {
        display: none !important;
        li > button:before {
            color: white !important;
        }
    }
}

.slick-slide {
    .featured-article {
        padding: 5%;
    }
}


@media only screen and (max-width: 768px) {
    .carousel-custom {
        //width: 100%;
        height: auto;
        overflow-x: hidden;

        .slick-track {
            width: 100px;
        }

        div {
            width: 90%;
            margin-left: 0;
            margin: 0 0.5vw;
        }

        a, span {
            width: 90%;
        }
    }
}
