$footer-links-color: #B9B9B9;
$primary-color: #03050D;
$light-font-color: #FFF;

.footer {
    background-color: $primary-color;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    padding: 50px 0;
}

.footer-div-flex{
    margin-top: 0 !important;
    display: flex;
    justify-content: center;
    background-color: $primary-color;

    .footer-div-information{
        margin: 0 24px;

        ul{
            margin-top: 16px;
            display: flex;
            flex-direction: column;

            li{
                padding: 4px 0;
                margin: 0 !important;
                
                a{
                    color: $light-font-color;
                }
            }
        }
    }
}

.footer-selection-title {
    font-size: 16px !important;
    font-weight: normal !important;
}

ul.footer-ul li{
    background-color: #03050D;
    margin-bottom: 16px;
    a{
        text-decoration: none;
        color: $footer-links-color;
        font-size: 14px;
        font-weight: 300;
        margin: 4px 4px 4px 0;
    }
}
.footer-copyright-div{
    span{
        font-size: 14px;
        font-weight: 300;
        color: $footer-links-color;
    }   
}

@media only screen and (max-width: 768px) {
    .footer {
        display: none;
    }
}