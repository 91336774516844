.featured-article{
    text-align: left;
    display: flex;
    flex-direction: column;
    padding: 2%;

    &__title {
        margin: 8px 0 0 0 ;
        color: #FFF;
        font-size: 22px;
        font-family: 'Roboto';
        font-weight: 500;
    }

    &__img {
        height: 250px;
        width: 100%;
        margin-bottom: 12px;

        img {
            width: 100%;
            object-fit: cover;
            height: 250px;
            height: -webkit-fill-available;
        }
    }
    

    &__category {
        margin-top: 8px;
        font-size: 14px;
        font-family: 'Lato';
        color: #FF9900;
    }
}


@media only screen and (max-width: 768px) {
    .article__featured-articles__list .featured-article {
        height: auto !important;
        
        &__title {
            height: auto;
        }

        padding: 1.6rem;
    }
}
   