$text-article-font-color: #595959;
$links-article-font-color: #5534eb;
$primary-color: #03050D;
$light-font-color: #FFF;
$details-font-color: #FF9900;
$dark-blue-color: #050916;
$description-preview-color: #7F7F7F;
$title-color: #333;

.article {
    width: 100%;
    display: flex;
    flex-direction: column;
    &__header {
        height: 600px;
        width: 100%;
        background-color: rgba(5, 9, 22, 0.95);
        display: flex;
        flex-direction: column-reverse;

        &__wrapper {

            display: flex;
            flex-direction: column;
            padding: 0 100px 50px 100px;


            h1 {
                color: #f9fafa;
                font-weight: 300;
                bottom: 0;
                font-size: 3em;
                width: 70%;
            }


            .additional-info {
                width: 70%;
                flex-direction: row-reverse;
                span {
                    margin: 5px;
                    color: #ff9900;
                    font-size: 12px;
                    padding: 10px;
                    border: 2px solid rgba(255, 153, 0, 1);
                    width: 10vw;
                    text-align: center;
                }
                display: flex;
            }
        }

        img {
            pointer-events: none;
            position: absolute;
            width: 100%;
            z-index: -1;
            object-fit: cover;
            filter: opacity(0.9);
            height: 600px;
            height: -webkit-fill-available;
        }

    }

    &__body {
        z-index: 1;
        margin-top: 50px;
        background-color: #f9fafa;
        padding: 0 25% 50px 25%;
        font-size: 16px;

        table {
            border-collapse: collapse;
            
            th, td {
                padding: 5px;
                border: 1px solid black;
            }
        }

        p {
            text-align: left !important;
            line-height: 160%;

            a {

                color: #ff9900;
                font-weight: 800;
                text-decoration: none;
            }
        }

        ul {
            padding: revert;
            list-style: disc;
        }

        img {
            width: 100%;
            margin: 15px 0;
        }

        h2 {
            text-align: left !important;
            font-size: 32px;
            font-weight: lighter;
            color: #ff9900;
        }
    }

    &__featured-articles {
        display: flex;
        background-color: #050916;
        flex-direction: column;
        padding: 0 100px;

        &__title{

            font-size: 2.0em;
            line-height: 1;
            margin: 32px 0;

        }

        &__list {
            display: flex;
            margin-bottom: 32px;
            justify-content: space-between;

            a {
                width: 33%;
            }

            .featured-article {
                transition: filter 0.5s;
                cursor: pointer;

                &:hover {
                    filter: opacity(0.5);
                }
                a {
                    font-size: 1em;
                    width: 100%;
                }
            }
        }
    }
}


@media only screen and (max-width: 1024px) {
    .article {
        .article-header {
            .image-article-header {
                img {
                    height: auto;
                }
            }
        }
    }
}

@media only screen and (max-width: 768px) {

    .article {
        overflow-x: hidden;
        &__header {
            height: 200px;
            &__wrapper {
                display: flex;
                flex-direction: column;
                padding: 0 5% 12.5px 5%;

                h1 {
                    font-size: 1.2em;
                    width: 100%;
                }


                .additional-info {
                    width: 100%;
                    span {
                        font-size: 8px;
                        padding: 10px;
                        width: 13vw;
                    }
                }
            }
        }

        &__body {
            margin-top: 20px;
            padding: 0 5% 12.5px 5%;
            width: 90%;
            font-size: 10px;
            h2 {
                font-size: 1.2em;
            }
        }

        &__featured-articles {
            padding: 0 5% 12.5px 5%;
            background-color: #050916;
            padding-bottom: 50px;

            &__title{
                font-size: 1.0em;
                line-height: 3;
                text-align: center;
            }

            &__list {
                flex-direction: column;
                justify-content: center;
                align-items: center;
                a {
                    width: 100%;
                }

            }


        }

    }


}

