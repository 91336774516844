$primary-color: #03050D;
$background-color: #FFF;
$font-style: 'Roboto';
$font-color: #000;
$title-color: #333;
$light-font-color: #FFF;
$details-font-color: #FF9900;
$description-preview-color: #7F7F7F;
$sub-description-preview-color: #B9B9B9;
$footer-links-color: #B9B9B9;
$button-background-color: #FF9900;
$dark-blue-color: #050916;
$text-article-font-color: #595959;
$links-article-font-color: #5534eb;

body {
  background-color: #f9fafa;
}

a {
  text-decoration: none !important;
}

.button {
  background: none;
  border-width: 2px;
  font-family: 'Roboto', sans-serif;
  padding: 14px 40px 14px 40px;
  cursor: pointer;
  border-style: solid;
  text-align: center;
  margin-bottom:10px;

  &--full {
    width: 100%;
  }

  &--orange {
    background-color: #ff9900;
    color: white;
  }

  &--white {
    border-color: #FFF;
    color: #FFF;
  }
}