.topbar-responsive {
    justify-content: center;

    .logo {
        margin-right: 21%;
    }

    .toggle-menu {
        margin-left: 21%;
    }
}
