.articles {
    width: 70%;

    .post-list {
        max-height: 700px;
        overflow-y: scroll;
        width: 100%;
        h3 {
            position: sticky;
            top: 0;
            margin: 0;
            padding: 32px 0;
        }
    }
}

@media only screen and (max-width: 768px) {
    .articles {
        width: 100% !important;
        .post-list div {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin: 5%;

            .article-item {
                height: auto;
                
                &__info{
                    align-items: flex-start;
                    margin: 2.5% 5%;
                    height: auto;

                    a {
                        text-align: start;
                    }

                    .description-div {
                        height: auto;
                    }

                    .sub-description-div {
                        align-items: flex-start;
                    }

                    div {
                        margin: 5px 0;
                    }
                }
            }
        }
    }
}
