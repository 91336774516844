.article-item {
    display: flex;
    align-items: center;
    height: 200px;
    background-color: #fdfdfd;
    box-shadow: 0px 6px 10px rgba(0,0,0,0.15);
    margin-bottom: 20px;
    padding-bottom: 20px;
    padding: 2%;
    filter: drop-shadow(2);

    img {
        width: 300px;
        height: 100%;
        padding-right: 10px;
    }

    &__info {
        display: flex;
        flex-direction: column;
        padding-top: 20px;
        height: 200px;
        padding-right: 15px;
    }
}

.article-preview-div {
    display: flex;
    height: 145px;
    margin: 0 0 64px 0;

    img {
        height: 145px;
        margin-right: 5vw;
    } 
}

.title{
    cursor: pointer;
    font-size: 22px;
    font-weight: 400;
    color: #000;
    text-decoration: none;

}

.description{
    font-family: 'Lato', sans-serif;
    font-weight: 300;
    font-size: 16px;
    line-height: 23px;
    color: #000;
    margin: 8px 0 8px 0;
}

.description-div{
    height: 95px;
}

.sub-description-div{
    display: flex;
    justify-content: start;

    .category-div, .timeToRead-div, .date-div{
        span{
            color: #000;
            font-family: 'Lato', sans-serif;
            font-weight: 300;
            font-size: 14px;}
    }

    .timeToRead-div, .category-div{
        margin-left: 16px;
    }
}


@media only screen and (max-width: 768px) {

    .article-item {
        width: 80%;
        flex-direction: column-reverse !important;
        height: 400px;
        margin-top: 0 !important;

        img {
            //display: none;
            width: 100%;
            margin: 0;
        }

        &__info {
            display: block;
            .description {
                font-size: 14px !important;
                line-height: 20px;
            }
        }

        .title {
            font-size: 1rem;
        }
    }

}