//.carousel-custom {
//    margin-top: 100px;
//    width: 90%;
//    height: auto;
//}



.posts {
    display: flex;
    background-color: white;
    flex-direction: row;
    padding: 75px 6.94%;
    justify-content: space-between !important;

    .sub-description-div {
        margin: 15px 0;
    }

    h3 {
        margin-bottom: 24px;
        padding-left: 2%;
    }
}

.slick-list {
    padding-bottom: 50px !important;
}

.right-section {
    width: 20%;
}

@media only screen and (min-width: 1440px) {
    .slick-track {
        padding-left: 0.5vw;
    }
}

@media only screen and (max-width: 768px) {
    //.carousel-custom{
    //    width: 100%;
    //    height: auto;
    //
    //    div {
    //        // max-width: 90%;
    //        width: 90%;
    //        margin-left: 0;
    //        // padding-left: 20px;
    //        margin: 0 0.5vw;
    //    }
    //}

    .right-section {
        width: 100%;
        
        margin-top: 65px
    }

    .slick-initialized .slick-slide {
        width: 44.05vw !important;
    }

    .slick-track {
        padding-left: 0.5vw;
    }

    .posts {
        flex-direction: column-reverse;
        padding: 0 0 5px 0;

        h3 {
            margin: 20px 5%;
            text-align: center;
        }

        .post-list {
            padding-left: 0;
            width: 100%;
            max-height: 80vh;

            h3 {
                margin: 0;
            }
            
            .article-preview-div {
                height: auto;
                width: auto;
                padding: 0 5%;
                flex-wrap: wrap;
                margin-left: 0;
                flex-direction: column-reverse;

                a {
                    text-align: center;
                }

                img {
                    width: 100%;
                    height: auto;
                }

                .description-div {
                    height: auto;
                }
            }
        }
    }
}

@media only screen and (max-width: 440px) {
    .slick-track {
        padding-left: 10vw;
    }

    .slick-initialized .slick-slide {
        width: 89vw !important;
    }
}